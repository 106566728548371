import { FunctionComponent, ReactNode } from 'react'
import cx from 'classnames'

const PageTitle: FunctionComponent<{
  className?: string
  children: ReactNode
}> = ({ children, className }) => {
  return <h2 className={cx(className)}>{children}</h2>
}

export default PageTitle
