/* eslint-disable no-console */
import Axios, { AxiosInstance, AxiosResponse } from 'axios'

export enum Redeemability {
  CURRENCY_MISMATCH = 'CURRENCY_MISMATCH',
  GC_INVALID = 'GC_INVALID',
  GC_USED = 'GC_USED',
  REDEEMABLE = 'REDEEMABLE',
  STRIPE_CUSTOMER_NOT_FOUND = 'STRIPE_CUSTOMER_NOT_FOUND',
}

export enum Status {
  ISSUED = 'ISSUED',
  REDEEMED = 'REDEEMED',
  CANCELED = 'CANCELED',
}

export interface RedeemSession {
  currency: string
  accessToken: string
  amount: number
}

export interface PaymentSession {
  familyAlbumUuid: string
  currency: string
  stripeCustomerId: string
  amount: number
  clientSecret: string | null
  currentBalance: number | null
  card: {
    brand: string
    last4: string
    expMonth: number
    expYear: number
  }
}

export class ApiClient {
  api: AxiosInstance
  baseURL: string = ''

  constructor({ baseURL }: { baseURL: string }) {
    this.baseURL = baseURL || `http://localhost:8003`

    this.api = Axios.create({
      withCredentials: true,
      baseURL: this.baseURL,
    })
  }

  authenticateRedeemSession = async (transitToken: string) => {
    const response = await this.api.post<any, AxiosResponse<RedeemSession>>(
      `/v1/redeem-sessions/${transitToken}`
    )

    return response.data
  }

  redeemGiftCardWithAccessToken = async (accessToken: string) => {
    return this.api.post<
      any,
      AxiosResponse<{
        newBalanceAmount: number
        currency: string
      }>
    >(
      '/v1/balance/',
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
  }

  redeemGiftCardWithRedemptionCode = async ({
    familyAlbumUuid,
    redemptionCode,
    stripeCustomerId,
    token,
  }: {
    familyAlbumUuid: string
    redemptionCode: string
    stripeCustomerId: string
    token: string
  }) => {
    const { data } = await this.api.post<
      any,
      AxiosResponse<{
        newBalanceAmount: number
        currency: string
        redeemedAmount: number
      }>
    >(
      '/v1/payment_sessions/redeem-gift-card',
      {
        faUuid: familyAlbumUuid,
        redemptionCode,
        stripeCustomerId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }

  getPaymentSession = async (token: string, setupIntent?: string) => {
    const response = await this.api.get<any, AxiosResponse<PaymentSession>>(
      '/v1/payment_sessions',
      {
        params: {
          setupIntent,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  }

  createPaymentIntent = async (redeemSessionToken: string) => {
    const response = await this.api.post<
      any,
      AxiosResponse<{ clientSecret: string }>
    >(`/v1/redeem-session/${redeemSessionToken}/payment-intent`)

    return response.data
  }

  confirmOrder = async (token: string) => {
    const response = await this.api.post<
      any,
      AxiosResponse<{
        balanceAmount: number
        cardAmount: number
        status: string
        successUrl: string
      }>
    >(
      `/v1/payment_sessions/confirm-order`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  }
}

export default ApiClient
