import ApiClient from '../../ApiClient'
import Header from '../../shared/Header'
import PageTitle from '../../shared/PageTitle'
import recipientGuide from '../../img/fa-gift-card-recipient-guide.png'
import { useCallback, useEffect, useRef, useState } from 'react'
import Spinner from '../../shared/Spinner'
import { isAxiosError } from 'axios'
import ErrorPage from '../../shared/ErrorPage'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import AnchorButton from '../../shared/buttons/AnchorButton'
import GiftCardFrame from './GiftCardFrame'

const ValidRedemptionLanding = ({
  api,
  transitToken,
  familyAlbumStoreDeeplink,
}: {
  api: ApiClient
  transitToken: string | null
  familyAlbumStoreDeeplink: string
}) => {
  const [error, setError] = useState<{
    message: string
    code?: number | null
  }>({
    message: '',
    code: null,
  })

  const [isAutoRedemptionDone, setIsAutoRedemptionDone] = useState(false)
  const [{ currency, amount }, setRedeemSession] = useState<{
    amount: number
    currency: string
  }>({
    amount: 0,
    currency: '',
  })

  const initialized = useRef(false)

  const authenticateAndRedeem = useCallback(
    async (token: string) => {
      setIsAutoRedemptionDone(false)
      try {
        const response = await api.authenticateRedeemSession(token)
        setRedeemSession({
          currency: response.currency,
          amount: response.amount,
        })

        await api.redeemGiftCardWithAccessToken(response.accessToken)
      } catch (err) {
        if (isAxiosError(err)) {
          setError({ message: err.message, code: err.status })
        } else {
          setError({ message: (err as Error).message, code: null })
        }
      } finally {
        setIsAutoRedemptionDone(true)
      }
    },
    [api]
  )

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true

      if (transitToken) {
        authenticateAndRedeem(transitToken)
      }
    }
  }, [transitToken, isAutoRedemptionDone, authenticateAndRedeem])

  if (!isAutoRedemptionDone) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="text-center mt-20">
          <div>Processing...</div>
          <Spinner className="w-52" />
        </div>
      </div>
    )
  }

  if (error.message) {
    return <ErrorPage message={error.message} error={error.code} />
  }

  const formattedAmount = currency
    ? Intl.NumberFormat('en-US', {
        style: 'currency',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        currency,
      }).format(amount / 100)
    : ''

  return (
    <div>
      <div className="bg-[#E8F7FD] text-center p-4">
        <p>
          <CheckCircleIcon className="w-5 text-[#71C76B] align-text-bottom mr-2 inline-block" />
          <strong>
            {formattedAmount} {currency}
          </strong>{' '}
          has been added
        </p>
        <p>to your account balance</p>
      </div>
      <div className="px-4 py-8">
        <GiftCardFrame formattedAmount={formattedAmount} currency={currency} />
      </div>
      <div className="px-4 pb-8 space-y-8">
        <div>
          <h2 className="font-bold mb-4">How to use your balance:</h2>
          <ol className="list-decimal pl-6 space-y-1 mb-8">
            <li>Open the FamilyAlbum app and visit the Store</li>
            <li>Choose physical products and customize</li>
            <li>
              At checkout, select "Gift Card Balance" as your payment method
            </li>
          </ol>
        </div>

        <img
          src={recipientGuide}
          alt="How to use your balance"
          className="mb-8"
        />
        <p className="text-fa-text-secondary mb-8">
          Your gift card balance can be used to purchase FamilyAlbum products*
          like Photo Prints, Photobooks, and more through the app. Don't worry,
          your balance never expires!
        </p>
        <p className="text-fa-text-secondary text-s">
          *Restrictions: Not applicable to premium subscriptions, digital
          products, or items from third-party partners.
        </p>
        <AnchorButton
          colorVariant="primary"
          className="block w-full py-3 text-center"
          href={familyAlbumStoreDeeplink}
        >
          Visit store
        </AnchorButton>
      </div>
    </div>
  )
}

export default ValidRedemptionLanding
