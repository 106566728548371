import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useCallback } from 'react'
import ApiClient from '../../../ApiClient'
import CreditCardForm from '../components/CreditCardForm'

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY)

const CreditCardFrame = ({
  api,
  navigateToDefault,
  clientSecret,
}: {
  api: ApiClient
  navigateToDefault: Function
  clientSecret?: string | null
}) => {
  const onNavigateToDefault = useCallback(() => {
    navigateToDefault()
  }, [navigateToDefault])

  return (
    <>
      <div className="w-full mx-auto">
        {clientSecret && (
          <Elements
            stripe={stripePromise}
            options={{
              mode: 'setup',
              currency: 'usd',
              paymentMethodTypes: ['card'],
            }}
          >
            <CreditCardForm
              onNavigateToDefault={onNavigateToDefault}
              clientSecret={clientSecret}
            />
          </Elements>
        )}
      </div>
    </>
  )
}

export default CreditCardFrame
