import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import ApiClient, { Redeemability } from '../../ApiClient'
import ValidRedemptionLanding from './ValidRedemptionLanding'
import InvalidGiftCard from './errors/InvalidGiftCard'
import GiftCardAlreadyRedeemed from './errors/GiftCardAlreadyRedeemed'
import CurrencyNotAvailableInRegion from './errors/CurrenyNotAvailableInRegion'
import { useCallback, useEffect, useState } from 'react'
import ErrorPage from '../../shared/ErrorPage'

const ROUTES = {
  root: '/',
  success: '/success',
  invalid: '/error/invalid',
  alreadyRedeemed: '/error/already-redeemed',
  currencyNotAvailbale: '/error/currency-not-available-in-region',
  missingParam: '/error/400',
}

const getRoute = (path: string) => '/redeem-gift-card' + path

const GiftCardAutoRedemption = ({
  api,
  familyAlbumStoreDeeplink,
}: {
  api: ApiClient
  familyAlbumStoreDeeplink: string
}) => {
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const redeemability = params.get('redeemability')
  const [transitToken] = useState(params.get('transitToken'))

  const validate = useCallback(() => {
    if (!redeemability) {
      navigate(getRoute(ROUTES.missingParam), {
        replace: true,
      })
    } else if (redeemability !== Redeemability.REDEEMABLE) {
      if (redeemability === Redeemability.CURRENCY_MISMATCH) {
        navigate(getRoute(ROUTES.currencyNotAvailbale), {
          replace: true,
        })
      } else if (redeemability === Redeemability.GC_INVALID) {
        navigate(getRoute(ROUTES.invalid), {
          replace: true,
        })
      } else if (redeemability === Redeemability.GC_USED) {
        navigate(getRoute(ROUTES.alreadyRedeemed), {
          replace: true,
        })
      } else {
        navigate(getRoute(ROUTES.invalid), {
          replace: true,
        })
      }
    } else if (!transitToken) {
      navigate(getRoute(ROUTES.missingParam), {
        replace: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  useEffect(() => {
    validate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Routes>
      <Route
        path={ROUTES.root}
        element={
          <ValidRedemptionLanding
            familyAlbumStoreDeeplink={familyAlbumStoreDeeplink}
            api={api}
            transitToken={transitToken}
          />
        }
      />

      <Route path={ROUTES.invalid} element={<InvalidGiftCard api={api} />} />
      <Route
        path={ROUTES.alreadyRedeemed}
        element={<GiftCardAlreadyRedeemed api={api} />}
      />
      <Route
        path={ROUTES.currencyNotAvailbale}
        element={<CurrencyNotAvailableInRegion api={api} />}
      />
      <Route
        path={ROUTES.missingParam}
        element={<ErrorPage error="400" message="Missing transit token" />}
      />
    </Routes>
  )
}

export default GiftCardAutoRedemption
