import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FormEventHandler, MouseEventHandler, useState } from 'react'
import toast from 'react-hot-toast'
import Footer from '../../../shared/Footer'
import Button from '../../../shared/buttons/Button'

const CreditCardForm = ({
  onNavigateToDefault,
  clientSecret,
}: {
  onNavigateToDefault: MouseEventHandler
  clientSecret: string
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [isLoading, setIsLoading] = useState(false)

  if (!stripe || !elements) {
    return null
  }

  const handleSubmit: FormEventHandler = async (event) => {
    event.preventDefault()
    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      toast.error(submitError?.message || 'ERROR')
      return
    }

    // Use the clientSecret and Elements instance to confirm the setup
    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            address: {
              postal_code: '',
            },
          },
        },
        return_url: window.location.href,
      },
    })

    if (error) {
      toast.error(error?.message || 'ERROR')
    }

    setIsLoading(false)
  }

  return (
    <>
      <form id="card-form" onSubmit={handleSubmit} className="w-11/12 mx-auto">
        <PaymentElement
          options={{
            wallets: {
              applePay: 'auto',
              googlePay: 'auto',
            },
            fields: {
              billingDetails: {
                address: {
                  postalCode: 'never',
                },
              },
            },
          }}
        />
      </form>
      <Footer className="absolute bottom-0 w-full">
        <div className="flex items-center justify-between space-x-2">
          <Button
            onClick={onNavigateToDefault}
            colorVariant="primary:invert"
            className="w-full"
          >
            Back
          </Button>
          <Button
            colorVariant="primary"
            className="w-full"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Add
          </Button>
        </div>
      </Footer>
    </>
  )
}

export default CreditCardForm
