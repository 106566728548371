import { ChevronLeftIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { isEmpty } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { PaymentSession } from '../../../ApiClient'
import giftCard from '../../../img/fa-logo-box.png'
import Card from '../../../shared/Card'
import Footer from '../../../shared/Footer'
import Button from '../../../shared/buttons/Button'
import HelperBlock from '../components/HelperBlock'
import { formatAmountString } from '../../../utils'

const DefaultFrame = ({
  navigateToCreditCard,
  navigateToGiftCardRedemption,
  paymentSession,
  onConfirmOrderClick,
  isConfirmingOrder,
}: {
  navigateToCreditCard: Function
  navigateToGiftCardRedemption: Function
  paymentSession: PaymentSession
  onConfirmOrderClick: Function
  isConfirmingOrder: boolean
}) => {
  const [remainingBalance, setRemainingBalance] = useState(0)
  const [currentBalance, setCurrentBalance] = useState(0)
  const [shouldShowHelperBlock, setShouldShowHelperBlock] = useState(false)
  const [shouldShowCreditCards, setShouldShowCreditCards] = useState(false)
  const [canConfirmOrder, setCanConfirmOrder] = useState(false)
  const currency = paymentSession?.currency || 'USD'
  const card = paymentSession?.card

  useEffect(() => {
    if (!paymentSession || paymentSession.amount === null) {
      setRemainingBalance(0)
      setCurrentBalance(0)
      setShouldShowHelperBlock(false)
      setShouldShowCreditCards(false)
      return
    }

    const newCurrentBalance =
      paymentSession.currentBalance !== null ? paymentSession.currentBalance : 0
    const newRemainingBalance = Math.max(
      paymentSession.amount - newCurrentBalance,
      0
    )
    const newShouldShowHelperBlock = !newCurrentBalance
    const newShouldShowCreditCards =
      newRemainingBalance > 0 && !!newCurrentBalance

    // The order can be confirmed:
    //   - if the user has balance greater than or equal to the order amount
    // OR
    //   - if the user has some balance and a credit card on file.
    const newCanConfirmOrder =
      newCurrentBalance >= paymentSession.amount ||
      (newCurrentBalance > 0 && !isEmpty(card))

    setRemainingBalance(newRemainingBalance)
    setCurrentBalance(newCurrentBalance)
    setShouldShowHelperBlock(newShouldShowHelperBlock)
    setShouldShowCreditCards(newShouldShowCreditCards)
    setCanConfirmOrder(newCanConfirmOrder)
  }, [card, paymentSession])

  const onNavigateToGiftCardRedemption = useCallback(() => {
    navigateToGiftCardRedemption()
  }, [navigateToGiftCardRedemption])

  const onNavigateToCreditCard = useCallback(() => {
    navigateToCreditCard()
  }, [navigateToCreditCard])

  return (
    <>
      <div className="flex-grow">
        <Card className="space-y-4 mb-4">
          <h3>Pay with Gift Card Balance</h3>
          <div className="grid grid-cols-3 items-center">
            <div className="">
              <img src={giftCard} alt="FamilyAlbum Gift Card" />
            </div>
            <div className="col-span-2 text-right space-y-2">
              <div className="text-fa-text-secondary text-s">
                Current Balance
              </div>
              <p className="font-bold text-xl">
                {formatAmountString(currentBalance, currency)}
              </p>
            </div>
          </div>
          <button
            onClick={onNavigateToGiftCardRedemption}
            className="text-fa-primary flex items-center"
          >
            <PlusIcon className="h-4 mr-2" />
            Redeem a gift card
          </button>
          <div className="pt-4 border-object-separator-1 border-t border-solid flex items-center justify-between">
            <div className="font-bold text-base">Payment Due</div>
            <p className="font-bold">
              {formatAmountString(remainingBalance, currency)}
            </p>
          </div>
          {shouldShowCreditCards && (
            <div className="space-y-2">
              <p className="text-fa-text-secondary">Pay with</p>
              {!isEmpty(card) && (
                <div className="flex justify-between items-center">
                  <div>
                    <div>
                      {card.brand} **** **** **** {card.last4}
                    </div>
                    <small className="text-fa-text-secondary">
                      Expires {card.expMonth}/{card.expYear}
                    </small>
                  </div>
                  <button
                    onClick={onNavigateToCreditCard}
                    className="text-fa-primary flex items-end"
                  >
                    Update
                  </button>
                </div>
              )}
              {isEmpty(card) && (
                <button
                  onClick={onNavigateToCreditCard}
                  className="text-fa-primary flex items-center"
                >
                  <PlusIcon className="h-4 mr-2" />
                  Add a credit card
                </button>
              )}
            </div>
          )}
        </Card>
        {shouldShowHelperBlock && (
          <HelperBlock>
            *No Balance? Redeem your gift card code and load your balance. If
            you don't have one, tap <XMarkIcon className="w-4 inline-block" />{' '}
            or <ChevronLeftIcon className="w-4 inline-block" /> to return to the
            previous page and choose the Credit Card or Paypal payment method.
          </HelperBlock>
        )}
      </div>
      <Footer className="mt-auto w-full">
        <div className="flex items-center justify-between space-x-2">
          <Button
            colorVariant="primary"
            className="w-full"
            isLoading={isConfirmingOrder}
            disabled={!canConfirmOrder || isConfirmingOrder}
            onClick={() => onConfirmOrderClick()}
          >
            Confirm Order
          </Button>
        </div>
      </Footer>
    </>
  )
}

export default DefaultFrame
