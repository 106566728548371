import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ApiClient, { PaymentSession } from '../../ApiClient'
import Card from '../../shared/Card'
import ErrorPage from '../../shared/ErrorPage'
import CreditCardFrame from './frames/CreditCardFrame'
import DefaultFrame from './frames/DefaultFrame'
import RedeemGiftCardFrame from './frames/RedeemGiftCardFrame'
import { formatAmountString } from '../../utils'
import ProgressBar from './components/ProgressBar'

enum Frame {
  'DEFAULT' = 'DEFAULT',
  'CC_FORM' = 'CC_FORM',
  'REDEEM' = 'REDEEM',
}

const Pending = ({
  api,
  onConfirmOrderClick,
  redeemGiftCard,
  paymentSession,
  redemptionErrorMessage,
  isConfirmingOrder,
}: {
  api: ApiClient
  isConfirmingOrder: boolean
  onConfirmOrderClick: Function
  redeemGiftCard: (giftCardCode: string) => Promise<void>
  paymentSession: PaymentSession | null
  redemptionErrorMessage: string
}) => {
  const [frame, setFrame] = useState(Frame.DEFAULT)
  const [params] = useSearchParams()

  const currency = paymentSession?.currency
  const amount = paymentSession?.amount || 0
  const amountString = formatAmountString(amount, currency)

  const paymentSessionToken = params.get('paymentSessionToken') || ''

  const onNavigateToGiftCardRedemption = useCallback(() => {
    setFrame(Frame.REDEEM)
  }, [])

  const onNavigateToDefault = useCallback(() => {
    setFrame(Frame.DEFAULT)
  }, [])

  const onNavigateToCreditCard = useCallback(() => {
    setFrame(Frame.CC_FORM)
  }, [])

  const onRedeemGiftCardClick = useCallback(
    async (giftCardCode: string) => {
      await redeemGiftCard(giftCardCode)
      setFrame(Frame.DEFAULT)
    },
    [redeemGiftCard]
  )

  if (!paymentSessionToken) {
    return <ErrorPage error="400" message="Missing payment session token" />
  }

  return (
    <div className="h-screen space-y-4 flex flex-col">
      <div className="pt-4">
        <ProgressBar activeStep={2} />
      </div>
      <Card>
        <div className="flex items-center justify-between">
          <div className="font-bold text-base">Payment Amount</div>
          <p className="font-bold text-xl">{amountString}</p>
        </div>
      </Card>
      {frame === Frame.DEFAULT && paymentSession && (
        <DefaultFrame
          isConfirmingOrder={isConfirmingOrder}
          paymentSession={paymentSession}
          navigateToCreditCard={onNavigateToCreditCard}
          navigateToGiftCardRedemption={onNavigateToGiftCardRedemption}
          onConfirmOrderClick={onConfirmOrderClick}
        />
      )}
      {frame === Frame.REDEEM && (
        <RedeemGiftCardFrame
          navigateToDefault={onNavigateToDefault}
          errorMessage={redemptionErrorMessage}
          onRedeemGiftCardClick={onRedeemGiftCardClick}
        />
      )}
      {frame === Frame.CC_FORM && (
        <CreditCardFrame
          api={api}
          navigateToDefault={onNavigateToDefault}
          clientSecret={paymentSession?.clientSecret}
        />
      )}
    </div>
  )
}

export default Pending
