import ApiClient from '../../../ApiClient'
import Header from '../../../shared/Header'
import PageTitle from '../../../shared/PageTitle'

const InvalidGiftCard = (props: { api: ApiClient }) => {
  return (
    <div>
      <header>
        <Header className="font-bold text-xl px-14 py-12 text-center">
          <PageTitle>Invalid eGift Card</PageTitle>
        </Header>
      </header>
      <main className="px-4 space-y-8">
        <p>
          The eGift Card you're attempting to redeem is invalid. Please
          double-check the details and try again.
        </p>
        <p>
          If you encounter persistent issues, please reach out to our support
          team at{' '}
          <a
            className="text-fa-primary"
            href="mailto:global-support@family-album.com"
          >
            global-support@family-album.com
          </a>
          .
        </p>
      </main>
    </div>
  )
}

export default InvalidGiftCard
