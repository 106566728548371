import { ChangeEventHandler, FunctionComponent, MouseEventHandler } from 'react'
import cx from 'classnames'
import { EnvelopeIcon, LockClosedIcon } from '@heroicons/react/24/solid'

export const variants = {
  red: [
    'border',
    'border-transparent',
    'ring-1',
    'ring-fa-attention',
    'text-fa-attention',
    'ring-inset',
  ].join(' '),
  default: [
    'border',
    'border-object-separator-1',
    'bg-white',
    'text-fa-text-primary',
    'placeholder:text-object-separator-1',
    'placeholder-shown:text-object-separator-1',
    'outline-fa-primary',
  ].join(' '),
}
export const inputBaseClass =
  'w-full h-full outline-none py-4 pr-2.5 bg-transparent peer placeholder:font-bold placeholder-fa-md-gray focus:placeholder:opacity-0'

const iconVariants = {
  red: 'text-fa-attention',
  default: 'text-object-separator-1',
}

const iconToComponent = {
  mail: EnvelopeIcon,
  lock: LockClosedIcon,
}

type IconType = 'mail' | 'lock'

const Icon: FunctionComponent<{ icon?: IconType }> = ({ icon }) => {
  if (!icon) {
    return null
  }
  const IconComponent = iconToComponent[icon]
  if (IconComponent) {
    return <IconComponent className="w-5" />
  }

  return null
}

interface TextInputProps {
  name: string
  type: 'text' | 'email' | 'password' | 'date' | 'tel'
  className?: string
  value?: string | number
  variant?: 'red' | 'default'
  placeholder?: string
  onChange: ChangeEventHandler
  icon?: 'mail' | 'lock'
  onClick?: MouseEventHandler
  autoComplete?: string
}

const TextInput: FunctionComponent<TextInputProps> = ({
  className,
  variant,
  icon,
  ...props
}) => {
  const variantClass = variants[variant || 'default']
  const iconVariantClass = iconVariants[variant || 'default']
  return (
    <div className={cx('rounded-lg relative', variantClass, className)}>
      <input
        className={cx(inputBaseClass, {
          'pl-9 focus:pl-2.5': !!icon,
          'pl-2.5': !icon,
        })}
        {...props}
      />
      <div
        className={cx(
          'absolute left-0 top-0 h-full flex items-center peer-focus:opacity-0 px-2.5',
          iconVariantClass
        )}
      >
        <Icon icon={icon} />
      </div>
    </div>
  )
}

export default TextInput
