import { ReactNode } from 'react'

const HelperBlock = (props: { children: ReactNode }) => {
  return (
    <div className="px-4 text-fa-text-secondary leading-[26px]">
      {props.children}
    </div>
  )
}

export default HelperBlock
