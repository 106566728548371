import { Redeemability } from '../ApiClient'

export const redeemabilityToMessage: {
  [key in Redeemability]: string | null
} = {
  [Redeemability.REDEEMABLE]: null,
  [Redeemability.STRIPE_CUSTOMER_NOT_FOUND]: null,
  [Redeemability.CURRENCY_MISMATCH]:
    'The code you are attempting to redeem is for a currency that does not match your account.',
  [Redeemability.GC_INVALID]:
    'The gift card code you entered is invalid. Please double-check and try again.',
  [Redeemability.GC_USED]: 'This gift card has already been redeemed.',
}
