import cx from 'classnames'
import familyAlbumEgiftCardBg from '../../img/fa-gift-card-flow-recipient.jpg'

const GiftCardFrame = ({
  currency,
  formattedAmount,
}: {
  currency: string
  formattedAmount: string
}) => (
  <div
    style={{ backgroundImage: `url(${familyAlbumEgiftCardBg})` }}
    className={cx(
      'pb-8 bg-cover relative rounded-b-2xl bg-center h-[300px] bg-no-repeat w-full rounded-t-2xl shadow-recipient-gift-card-box'
    )}
  >
    <div className="p-4 text-right">
      <p className="text-xl font-bold tracking-wide">
        {formattedAmount} {currency}
      </p>
      <p className="text-fa-text-secondary text-s">eGift card</p>
    </div>
  </div>
)

export default GiftCardFrame
