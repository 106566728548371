import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import GiftCardAutoRedemption from './pages/GiftCardAutoRedemption'
import './index.css'
import ApiClient from './ApiClient'
import { Toaster } from 'react-hot-toast'
import config from './config'
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'
import Payment from './pages/Payment'
import ErrorPage from './shared/ErrorPage'

const api = new ApiClient({
  baseURL: config.paymentsApiBaseUrl,
})

const router = createBrowserRouter([
  {
    path: '/redeem-gift-card/*',
    element: (
      <GiftCardAutoRedemption
        familyAlbumStoreDeeplink={config.familyAlbumStoreDeeplink}
        api={api}
      />
    ),
  },
  {
    path: '/payment/*',
    element: (
      <div className="bg-fa-bg-guide">
        <Payment api={api} />
      </div>
    ),
  },
  {
    path: '*',
    element: <ErrorPage error="404" message="Page not found" />,
  },
])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RollbarProvider config={config.rollbarConfig}>
      <ErrorBoundary
        fallbackUI={({ error }) => (
          <ErrorPage
            error={error?.name || 'Error'}
            message={error?.message || 'Something went wrong'}
          />
        )}
      >
        <Toaster position="top-center" />
        <RouterProvider router={router} />
      </ErrorBoundary>
    </RollbarProvider>
  </React.StrictMode>
)
