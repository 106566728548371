import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import Card from '../../../shared/Card'
import Footer from '../../../shared/Footer'
import TextInput from '../../../shared/TextInput'
import Button from '../../../shared/buttons/Button'
import HelperBlock from '../components/HelperBlock'
import { validateGiftCardRedemptionCode } from '../../../utils'

const RedeemGiftCardFrame = ({
  errorMessage,
  navigateToDefault,
  onRedeemGiftCardClick,
}: {
  errorMessage: string
  navigateToDefault: Function
  onRedeemGiftCardClick: (giftCardCode: string) => void
}) => {
  const [giftCardCode, setGiftCardCode] = useState('')
  const [formatErrorMessage, setFormatErrorMessage] = useState('')

  const onNavigateToDefault = useCallback(() => {
    setGiftCardCode('')
    navigateToDefault()
  }, [navigateToDefault])

  const onGiftCardCodeChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setFormatErrorMessage('')
      setGiftCardCode(evt.target.value.toLocaleUpperCase())
    },
    []
  )

  const onFormSubmit = useCallback(
    (evt: FormEvent) => {
      evt.preventDefault()
      const isValid = validateGiftCardRedemptionCode(giftCardCode)
      if (!isValid) {
        setFormatErrorMessage('Invalid gift card code format.')
      } else {
        onRedeemGiftCardClick(giftCardCode)
      }
    },
    [giftCardCode, onRedeemGiftCardClick]
  )

  const errorToShow = errorMessage || formatErrorMessage

  return (
    <>
      <div className="flex-grow">
        <Card className="space-y-4 mb-4">
          <h3>Redeem a gift card</h3>
          <form className="space-y-2" onSubmit={onFormSubmit}>
            <label htmlFor="giftCardCode" className="text-s font-bold">
              Gift card code
            </label>
            <TextInput
              name="giftCardCode"
              value={giftCardCode}
              onChange={onGiftCardCodeChange}
              type="text"
              placeholder="Enter gift card code"
            />
          </form>
          {errorToShow !== '' && (
            <div className="text-fa-attention font-bold text-s">
              <p>{errorToShow}</p>
              <p>
                If you need assistance, contact us at{' '}
                <a
                  className="underline"
                  href="mailto:global-support@family-album.com"
                >
                  global-support@family-album.com
                </a>
                .
              </p>
            </div>
          )}
        </Card>
        <HelperBlock>
          *The gift card amount will be securely stored in your FamilyAlbum
          account, with no expiration date. Once redeemed, the balance cannot be
          transferred to other accounts.
        </HelperBlock>
      </div>
      <Footer className="mt-auto w-full">
        <div className="flex items-center justify-between space-x-2">
          <Button
            onClick={onNavigateToDefault}
            colorVariant="primary:invert"
            className="w-full"
          >
            Back
          </Button>
          <Button
            colorVariant="primary"
            disabled={!giftCardCode}
            className="w-full"
            onClick={onFormSubmit}
          >
            Redeem
          </Button>
        </div>
      </Footer>
    </>
  )
}

export default RedeemGiftCardFrame
