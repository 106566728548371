import { FunctionComponent, ReactNode } from 'react'
import cx from 'classnames'
import bg from '../img/confetti_header.png'

const Header: FunctionComponent<{
  className?: string
  children: ReactNode
}> = ({ children, className }) => {
  return (
    <div className={cx('relative', className)}>
      <div
        className="bg-no-repeat w-full bg-contain absolute top-0 left-0"
        style={{
          height: '110px',
          backgroundPosition: '1px -10px',
          backgroundImage: `url(${bg})`,
        }}
      ></div>
      {children}
    </div>
  )
}

export default Header
