import ApiClient from '../../../ApiClient'
import Header from '../../../shared/Header'
import PageTitle from '../../../shared/PageTitle'

const GiftCardAlreadyRedeemed = (props: { api: ApiClient }) => {
  return (
    <div>
      <header>
        <Header className="font-bold text-xl px-14 py-12 text-center">
          <PageTitle>eGift Card already redeemed</PageTitle>
        </Header>
      </header>
      <main className="px-4 space-y-8">
        <p>
          The eGift Card you're attempting to redeem has already been used. Gift
          cards can only be redeemed once.
        </p>
        <p>
          If you have any questions or need assistance, please don't hesitate to
          contact us at{' '}
          <a
            className="text-fa-primary"
            href="mailto:global-support@family-album.com"
          >
            global-support@family-album.com
          </a>
          .
        </p>
      </main>
    </div>
  )
}

export default GiftCardAlreadyRedeemed
