import { useRollbar } from '@rollbar/react'
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ApiClient, { PaymentSession, Redeemability } from '../../ApiClient'
import ErrorPage from '../../shared/ErrorPage'
import Spinner from '../../shared/Spinner'
import { formatAmountString } from '../../utils'
import Pending from './Pending'
import { toast } from 'react-hot-toast'
import { isAxiosError } from 'axios'
import { redeemabilityToMessage } from '../../shared/constants'

const Payment = ({ api }: { api: ApiClient }) => {
  const rollbar = useRollbar()
  const [redemptionErrorMessage, setRedemptionErrorMessage] = useState('')
  const [isPaymentSessionLoaded, setIsPaymentSessionLoaded] = useState(false)
  const [isConfirmingOrder, setIsConfirmingOrder] = useState(false)
  const [params] = useSearchParams()
  const [paymentSession, setPaymentSession] = useState<PaymentSession | null>(
    null
  )

  const paymentSessionToken = params.get('paymentSessionToken') || ''
  const setupIntentFromStripe = params.get('setup_intent') || undefined

  const getPaymentSession = useCallback(
    async (token: string, setupIntent?: string) => {
      try {
        const response = await api.getPaymentSession(token, setupIntent)
        setPaymentSession(response)
      } finally {
        setIsPaymentSessionLoaded(true)
      }
    },
    [api]
  )

  const onConfirmOrderClick = useCallback(async () => {
    setIsConfirmingOrder(true)

    try {
      const { balanceAmount, cardAmount } = await api.confirmOrder(
        paymentSessionToken
      )

      const params = new URLSearchParams()

      params.append('cardLast4', paymentSession?.card?.last4 || '')
      params.append(
        'cardExpYear',
        paymentSession?.card?.expYear.toString() || ''
      )
      params.append(
        'cardExpMonth',
        paymentSession?.card?.expMonth.toString() || ''
      )
      params.append('paidBalance', balanceAmount.toString())
      params.append('paidCard', cardAmount.toString())
      params.append('currency', paymentSession?.currency || '')
      window.location.assign(`/success/index.html?${params.toString()}`)
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err.response?.data.message)
      } else {
        toast.error((err as Error).message)
      }
      rollbar.error('Error confirming payment', err as Error)
    } finally {
      setIsConfirmingOrder(false)
    }
  }, [
    api,
    paymentSession?.card?.expMonth,
    paymentSession?.card?.expYear,
    paymentSession?.card?.last4,
    paymentSession?.currency,
    paymentSessionToken,
    rollbar,
  ])

  const onRedeemGiftCardClick = useCallback(
    async (giftCardCode: string) => {
      try {
        const {
          redeemedAmount,
          newBalanceAmount,
          currency: balanceCurrency,
        } = await api.redeemGiftCardWithRedemptionCode({
          familyAlbumUuid: paymentSession?.familyAlbumUuid || '',
          redemptionCode: giftCardCode,
          stripeCustomerId: paymentSession?.stripeCustomerId || '',
          token: paymentSessionToken,
        })

        toast.success(
          `${formatAmountString(
            redeemedAmount,
            balanceCurrency
          )} has been added`
        )
        setPaymentSession({
          ...paymentSession,
          currentBalance: newBalanceAmount,
        } as PaymentSession)
      } catch (err) {
        if (isAxiosError(err)) {
          const errorName = err.response?.data.message as Redeemability
          const redeemabilityMessage = redeemabilityToMessage[errorName]
          setRedemptionErrorMessage(
            redeemabilityMessage || err.response?.data.message
          )
        } else {
          setRedemptionErrorMessage((err as Error).message)
        }

        throw err
      }
    },
    [api, paymentSession, paymentSessionToken]
  )

  useEffect(() => {
    // TODO: Fix double call here
    if (paymentSessionToken) {
      getPaymentSession(paymentSessionToken, setupIntentFromStripe)
    }
  }, [paymentSessionToken, getPaymentSession, setupIntentFromStripe])

  if (!paymentSessionToken) {
    return <ErrorPage error="400" message="Missing payment session token" />
  }

  if (!isPaymentSessionLoaded || !paymentSession) {
    return (
      <div className="h-full flex items-center justify-center">
        <Spinner className="w-60" />
      </div>
    )
  }

  return (
    <Pending
      api={api}
      paymentSession={paymentSession}
      redemptionErrorMessage={redemptionErrorMessage}
      onConfirmOrderClick={onConfirmOrderClick}
      redeemGiftCard={onRedeemGiftCardClick}
      isConfirmingOrder={isConfirmingOrder}
    />
  )
}

export default Payment
