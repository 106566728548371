import ApiClient from '../../../ApiClient'
import Header from '../../../shared/Header'
import PageTitle from '../../../shared/PageTitle'

const CurrencyNotAvailableInRegion = (props: { api: ApiClient }) => {
  return (
    <div>
      <header>
        <Header className="font-bold text-xl px-14 py-12 text-center">
          <PageTitle>eGift Card currency unavailable in your region</PageTitle>
        </Header>
      </header>
      <main className="px-4 space-y-8">
        <p>
          The currency of the gift card doesn't match the currency of your
          account. Please make sure you're using the correct gift card for your
          account's currency.
        </p>
        <p>
          Please contact us at{' '}
          <a
            className="text-fa-primary"
            href="mailto:global-support@family-album.com"
          >
            global-support@family-album.com
          </a>
          .
        </p>
      </main>
    </div>
  )
}

export default CurrencyNotAvailableInRegion
